import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordValid(validatePassword(newPassword));
  };

  const handleNext = () => {
    setError(''); // Reset error messages

    if (!username.trim()) {
      setError('O campo user name é obrigatório.');
      return;
    }

    if (!email.trim()) {
      setError('O campo Email é obrigatório.');
      return;
    }

    if (!password.trim()) {
      setError('O campo Palavra-passe é obrigatório.');
      return;
    }

    if (!isPasswordValid) {
      setError(
        'A palavra-passe deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma letra minúscula, um número e um caractere especial.'
      );
      return;
    }

    if (password !== confirmPassword) {
      setError('As palavras-passe não coincidem.');
      return;
    }

    // Navega para o próximo passo
    navigate('/register-step2', { state: { username, email, password } });
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Image
            src="./assets/camera.png"
            fluid
            alt="description of image"
          />
        </Col>
        <Col md={6}>
          <Card className="p-4 shadow-lg border-0 rounded-3">
            <Card.Body>
              <h2 className="mb-4 fw-bold textVet">Registo de centros de atendimento médico veterinários</h2>
              {error && <p className="text-danger text-center">{error}</p>}
              <Form>
                <Form.Group controlId="formBasicUsername" className="mb-3">
                  <Form.Label>Nome da clínica</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Introduzir nome da clínica"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="rounded px-3 py-2"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Introduzir email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="rounded px-3 py-2"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Palavra-passe</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Introduzir palavra-passe"
                    value={password}
                    onChange={handlePasswordChange}
                    className="rounded px-3 py-2"
                  />
                  {!isPasswordValid && password && (
                    <p className="text-danger">
                      A palavra-passe deve ter pelo menos 8 caracteres, conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.
                    </p>
                  )}
                </Form.Group>

                <Form.Group controlId="formBasicConfirmPassword" className="mb-3">
                  <Form.Label>Confirmar Palavra-passe</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirmar palavra-passe"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="rounded px-3 py-2"
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  onClick={handleNext}
                  className="w-100"
                >
                  Seguinte
                </Button>
              </Form>
              <div className="text-center mt-3">
                Já tens uma conta? <Link to="/login">Entrar</Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;

import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Table, Button, InputGroup, FormControl, Pagination, Modal, Form } from "react-bootstrap";
import { FaInfo, FaPlus, FaTrash, FaSearch } from "react-icons/fa"; // Import icons for actions
import ProfilePhotoSmallByUserId from "../../Photos/profilephotoSmallByUserId"
import "../../CSS/Userslist.css"
import path from "../../config";

function Users() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    email: "",
    password: "",
    role: "",
  });
  const [error, setError] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);


  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const token = localStorage.getItem("userToken");
    try {
      const response = await fetch(path + "user/users", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }

      const data = await response.json();
      setUsers(data);
      setFilteredUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getchUsersProfile = async (userId) => {
    const token = localStorage.getItem("userToken");

    try {
      const response = await fetch(path + `user/userprofile/${userId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }
  
      const data = await response.json();
  
      // Verificar se o perfil contém dados
      if (!data || Object.keys(data).length === 0) {
        // Aviso para o utilizador
        alert("Este utilizador ainda não possui dados de perfil.");
        return;
      }

      setSelectedUserProfile(data);
      setShowProfileModal(true); // Exibir o modal com os dados
    } catch (error) {
      console.error("Error fetching user profile:", error);
      alert("Este utilizador ainda não possui dados de perfil.");
    }
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    filterUsers(e.target.value);
  };

  const filterUsers = (term) => {
    const filtered = users.filter(
      (user) =>
        (user.username &&
          user.username.toLowerCase().includes(term.toLowerCase())) ||
        (user.email && user.email.toLowerCase().includes(term.toLowerCase()))
    );
    setFilteredUsers(filtered);
    setCurrentPage(1);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const generatePassword = () => {
    const length = 8;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const handleAddUser = async () => {
    const password = generatePassword();
    const newUserWithPassword = { ...newUser, password };

    try {
      const response = await fetch(path + "user/addUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify(newUserWithPassword),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Error adding user");
      }

      const data = await response.json();
      setUsers([...users, data]);
      setFilteredUsers([...filteredUsers, data]);
      setShowModal(false);
      setError("");
    } catch (error) {
      setError(error.message);
    }
  };

  const disabledUser = async (id) => {
    try {
      const resposta = await axios.put(path + `user/disable/${id}`);
      if (resposta.status === 200) {
        alert("Utilizador desativado com sucesso!"); // Exemplo de feedback
        // Atualize o estado ou refaça o fetch dos dados, se necessário
        fetchUsers();
      }
    } catch (error) {
      console.error("Erro ao desativar utilizador:", error);
      alert("Ocorreu um erro ao desativar o utilizador.");
    }
  };

  return (
    <div className="container">
      <div className="d-flex  align-items-center mb-4">
        <Button
          variant="primary"
          onClick={() => setShowModal(true)}
          className="d-flex align-items-center justify-content-center gap-2"
          style={{ padding: "10px 15px", fontSize: "1rem" }}
        >
          Novo utilizador
          <FaPlus size={18} color="white" />
        </Button>
      </div>


      <div className="d-flex align-items-center justify-content-end">
        {/* Se estiver em sm ou menor, exibe a lupa */}
        <div className="d-block d-sm-none">
          <Button
            variant="link"
            onClick={() => setIsSearchVisible(!isSearchVisible)}
          >
            <FaSearch size={20} color="black" /> {/* Ícone de pesquisa com a cor do botão */}
          </Button>
        </div>

        {/* Se o campo de pesquisa estiver visível, exibe o InputGroup abaixo da lupa */}
        {isSearchVisible && (
          <div className="d-flex align-items-center justify-content-end w-100 mt-2"> {/* Coloca o campo de pesquisa abaixo */}
            <InputGroup className="w-100">
              <FormControl
                placeholder="Procurar por nome ou email"
                value={searchTerm}
                onChange={handleSearch}
                autoFocus
              />
            </InputGroup>
          </div>
        )}

        {/* Em telas maiores (sm e acima), mantém o campo de pesquisa visível com tamanho maior */}
        <div className="d-none d-sm-block w-25"> {/* Tamanho maior para telas grandes */}
          <InputGroup>
            <FormControl
              placeholder="Procurar por nome ou email"
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
        </div>

      </div>


      <Table hover responsive className="shadow-sm table-modern no-border">
        <thead className="bg-secondary text-white align-middle">
          <tr>
            <th className="text-center" style={{ width: "5%" }}>ID</th>
            <th className="text-center" style={{ width: "15%" }}>Photo</th>
            <th style={{ width: "25%" }}>Nome</th>
            <th style={{ width: "30%" }} className="d-none d-sm-table-cell">Email</th>
            <th style={{ width: "30%" }} className="d-none d-sm-table-cell">Role</th>
            <th className="text-center" style={{ width: "10%" }}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td className="text-center">{user.id}</td>
              <td className="text-center"><ProfilePhotoSmallByUserId userid={user.id} /></td>
              <td>{user.username}</td>
              <td className="d-none d-sm-table-cell">{user.email}</td>
              <td className="d-none d-sm-table-cell">{user.Role}</td>
              <td className="text-center d-flex justify-content-center align-items-center">
                  <div size="lg" className="action-btn info-btn text-info" title="Editar" onClick={() => getchUsersProfile(user.id)}>
                    <FaInfo />
                  </div>
                  <div size="lg" className="action-btn delete-btn" title="Apagar" onClick={() => disabledUser(user.id)}>
                    <FaTrash />
                  </div>
                </td>

            </tr>
          ))}
        </tbody>
      </Table>


      <Pagination className="justify-content-center my-4">
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePaginationClick(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar novo utilizador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <div className="text-danger mb-3">{error}</div>}
          <Form>
            <Form.Group controlId="formUsername" className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={newUser.username}
                onChange={(e) =>
                  setNewUser({ ...newUser, username: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={newUser.email}
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formRole" className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                value={newUser.role}
                onChange={(e) =>
                  setNewUser({ ...newUser, role: e.target.value })
                }
              >
                <option value="">Selecionar Role</option>
                <option value="Client">Cliente</option>
                <option value="Anesthetist">Anestesista</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fechar
          </Button>
          <Button variant="primary" onClick={handleAddUser}>
            Adicionar Utilizador
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showProfileModal} onHide={() => setShowProfileModal(false)} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes do Perfil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUserProfile ? (
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p><strong>Nome:</strong> {selectedUserProfile.name}</p>
                  <p><strong>Morada:</strong> {selectedUserProfile.addressLine1}</p>
                  <p><strong>País:</strong> {selectedUserProfile.country}</p>
                  <p><strong>Distrito:</strong> {selectedUserProfile.district}</p>
                </div>
                <div className="col-md-6">
                  <p><strong>Cidade:</strong> {selectedUserProfile.city}</p>
                  <p><strong>Código-postal:</strong> {selectedUserProfile.postalCode}</p>
                  <p><strong>NIF:</strong> {selectedUserProfile.nif}</p>
                  {selectedUserProfile.iban && (
                    <p><strong>IBAN:</strong> {selectedUserProfile.iban}</p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <p>A carregar informações...</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowProfileModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default Users;

import React, { useEffect, useState } from "react";
import {  Row,  Col,  ListGroup,  Spinner,  Card,  Button,  Modal,  Pagination} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css"; // Importing the calendar styles
import path from "../config";
import { FaInfoCircle } from "react-icons/fa";

// Função para calcular o início e fim do mês atual
const getCurrentMonthDates = () => {
  const today = new Date();

  // Calcular o primeiro dia do mês
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  // Calcular o último dia do mês
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  // Formatar para YYYY-MM-DD
  const formatDate = (date) => date.toISOString().split("T")[0];

  return {
    startDate: formatDate(startOfMonth),
    endDate: formatDate(endOfMonth),
  };
};


function SchedulePage() {

  const navigate = useNavigate();

  const initialDate = () => {
    const storedDates = sessionStorage.getItem("scheduleFilters");
    return storedDates ? JSON.parse(storedDates) : getCurrentMonthDates();
  };

  const [date, setDate] = useState(initialDate());
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]); // State to hold users
  const [loadingUsers, setLoadingUsers] = useState(false); // Loading state for users
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedSchedule, setSelectedSchedule] = useState(null); // Track selected schedule
  const [selectedScheduleToRemove, setSelectedScheduleToremove] = useState(null); // Track selected schedule
  const [selectedUser, setSelectedUser] = useState(null); // Track selected user
  const [error, setError] = useState(""); // Para armazenar erros de validação
  const [onlyWithoutAnesthetist, setOnlyWithoutAnesthetist] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("scheduleFilters", JSON.stringify(date));
  }, [date]);

  // Function to format date as YYYY-MM-DD for the API
  const formatDateForApi = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Pagination state
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch schedules when the selected date changes
  const fetchSchedules = async () => {
    setError(""); // Resetar mensagem de erro
    setLoading(true);
  
    try {
      const formattedStartDate = formatDateForApi(date.startDate);
      const formattedEndDate = formatDateForApi(date.endDate);
  
      const start = new Date(date.startDate);
      const end = new Date(date.endDate);
  
      // Calcular a diferença em dias
      const diffTime = Math.abs(end - start);
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
  
      if (diffDays > 30) {
        setError("O intervalo entre as datas não pode ser superior a 30 dias.");
        setLoading(false);
        return;
      }
  
      // Adiciona o parâmetro `onlyWithoutAnesthetist` à URL
      const response = await fetch(
        path +
          `sche/GetSchedulesByRange?startDate=${formattedStartDate}&endDate=${formattedEndDate}&onlyWithoutAnesthetist=${onlyWithoutAnesthetist}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
  
      if (!response.ok) throw new Error("Failed to fetch schedules");
  
      const data = await response.json();
      setSchedules(data);
    } catch (error) {
      console.error("Error fetching schedules:", error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchSchedules();
  }, [date.startDate, date.endDate, onlyWithoutAnesthetist]);

  // Pagination logic
  const paginatedSchedules = schedules.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < schedules.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Fetch users with availability for the selected date
  // Fetch users with availability for the selected schedule's start and end dates
  useEffect(() => {
    const fetchUsersWithAvailability = async () => {
      if (!selectedSchedule) return; // Exit if no schedule is selected

      setLoadingUsers(true);
      try {
        const startDate = new Date(selectedSchedule.startDate);
        const endDate = new Date(selectedSchedule.endDate);

        const response = await fetch(
          path +
            `avail/GetUsersWithAvailability?startDate=${startDate}&endDate=${endDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );

        if (!response.ok)
          throw new Error("Failed to fetch users with availability");

        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error("Error fetching users with availability:", error);
      } finally {
        setLoadingUsers(false);
      }
    };

    fetchUsersWithAvailability();
  }, [selectedSchedule]); // Trigger when selectedSchedule changes

  const handleShowModal = (schedule) => {
    setSelectedSchedule(schedule); // Set the selected schedule
    setShowModal(true); // Show the modal
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user); // Set the selected user
  };

  // Function to associate anesthetist to schedule
  const assignAnesthetistToSchedule = async () => {
    if (!selectedSchedule || !selectedUser) return;

    try {
      const response = await fetch(path + `sche/AssignAnesthetist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({
          scheduleId: selectedSchedule.id,
          userId: selectedUser.creator.id,
        }),
      });

      if (!response.ok) throw new Error("Failed to assign anesthetist");

      console.log("Anesthetist assigned successfully!");
      fetchSchedules(); // Re-fetch schedules to update the page
      setSelectedUser(null);
      setSelectedSchedule(null);
      setShowModal(false); // Close modal on success
      // Optionally, you can refresh the schedules or display a success message
    } catch (error) {
      console.error("Error assigning anesthetist:", error);
    }
  };

  // Function to deassign anesthetist from schedule
  const deassignAnesthetistToSchedule = async () => {
    if (!selectedScheduleToRemove) return;

    try {
      const response = await fetch(path + `sche/DeassignAnesthetist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({
          scheduleId: selectedScheduleToRemove.id,
        }),
      });

      if (!response.ok) throw new Error("Failed to deassign anesthetist");

      console.log("Anesthetist deassigned successfully!");
      fetchSchedules(); // Re-fetch schedules to update the page
      setSelectedScheduleToremove(null);
    } catch (error) {
      console.error("Error deassigning anesthetist:", error);
    }
  };

  const handleDeassignClick = (schedule) => {
    setSelectedScheduleToremove(schedule); // Set the schedule to be deassigned
  };

  // Trigger deassignment when selectedScheduleToRemove changes
  useEffect(() => {
    if (selectedScheduleToRemove) {
      deassignAnesthetistToSchedule();
    }
  }, [selectedScheduleToRemove]);

  const handleConfirmSelection = () => {
    if (selectedUser) {
      console.log("Confirmed Selection:", selectedUser);
      assignAnesthetistToSchedule(); // Call the API to assign anesthetist to the selected schedule
    }
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
    setSelectedUser(null); // Clear the selected user when modal is closed
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(schedules.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="container">
      <Row>
      <h1 className="fw-bold mb-3 text-start">Dashboard</h1>
        <Col sm={12} className="d-flex">
          <div className="card p-3 w-100 shadow-sm" style={{ maxWidth: "100%" }}>
            <h5 className="fw-bold mb-3 text-start">Filtros</h5>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="d-flex flex-wrap align-items-center gap-3">
              <div className="d-flex flex-column">
                <label htmlFor="startDate" className="form-label mb-0">
                  Data de Início:
                </label>
                <input
                  type="date"
                  id="startDate"
                  className="form-control"
                  value={date.startDate}
                  onChange={(e) =>
                    setDate((prev) => ({ ...prev, startDate: e.target.value }))
                  }
                />
              </div>
              <div className="d-flex flex-column">
                <label htmlFor="endDate" className="form-label mb-0">
                  Data de Fim:
                </label>
                <input
                  type="date"
                  id="endDate"
                  className="form-control"
                  value={date.endDate}
                  onChange={(e) =>
                    setDate((prev) => ({ ...prev, endDate: e.target.value }))
                  }
                />
              </div>
              <div className="d-flex align-items-center">
              <input
                type="checkbox"
                id="onlyWithoutAnesthetist"
                className="form-check-input me-2"
                checked={onlyWithoutAnesthetist}
                onChange={(e) => setOnlyWithoutAnesthetist(e.target.checked)}
              />
              <label htmlFor="onlyWithoutAnesthetist" className="form-check-label">
                Apenas sem anestesista associado
              </label>
            </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={12}>
          {loading ? (
            <div className="text-center mt-4">
              <Spinner animation="border" />
            </div>
          ) : (
            <ListGroup className="mt-3">
              {schedules.length > 0 ? (
                <>
                  <Row className="g-3">
                    {paginatedSchedules.map((schedule) => (
                      <Col sm={6} md={4} lg={6} xl={4} key={schedule.id}>
                      <Card className="shadow-sm border-0 rounded-lg h-100">
                        <Card.Body className="d-flex flex-column">
                          {/* Data e Horários */}
                          <div className="mb-2">
                            <div
                              className="fw-bold"
                              style={{ fontSize: "1rem", marginBottom: "0.5rem" }}
                            >
                              {new Date(schedule.startDate).toLocaleDateString("pt-PT", {
                                weekday: "long",
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              })}
                            </div>
                            <div className="fw-bold" style={{ fontSize: "1rem" }}>
                              {new Date(schedule.startDate).toLocaleTimeString()} -{" "}
                              {new Date(schedule.endDate).toLocaleTimeString()}
                            </div>
                          </div>
                    
                          {/* Cliente */}
                          <div className="mb-2">
                            <div>
                              <strong>Cliente:</strong> {schedule.creator.username}
                            </div>
                            <div className="text-muted">
                              <strong>Email:</strong> {schedule.creator.email}
                            </div>
                          </div>
                    
                          {/* Anestesista */}
                          {schedule.associatedwithuserId !== null && (
                            <div className="mb-2">
                              <div>
                                <strong>Anestesista:</strong> {schedule.associatedUser.username}
                              </div>
                              <div className="text-muted">
                                <strong>Email:</strong> {schedule.associatedUser.email}
                              </div>
                            </div>
                          )}
                    
                          {/* Botões no canto inferior direito */}
                          <div className="mt-auto d-flex justify-content-end align-items-center gap-2">
                            <FaInfoCircle
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              title="Detalhes"
                              size={24}
                              onClick={() => navigate(`/Schedule/${schedule.id}`)} // Navega para os detalhes do evento
                            />
                            {schedule.associatedwithuserId == null ? (
                              <Button
                                variant="success"
                                className="me-2"
                                onClick={() => handleShowModal(schedule)}
                              >
                                Associar Anestesista
                              </Button>
                            ) : (
                              <Button
                                variant="outline-danger"
                                onClick={() => handleDeassignClick(schedule)}
                              >
                                Desassociar Anestesista
                              </Button>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    ))}
                  </Row>

                  <div className="d-flex justify-content-center mt-5">
                    <Pagination>
                      {pageNumbers.map((page) => (
                        <Pagination.Item
                          key={page}
                          active={page === currentPage}
                          onClick={() => setCurrentPage(page)}
                        >
                          {page}
                        </Pagination.Item>
                      ))}
                    </Pagination>
                  </div>
                </>
              ) : (
                <ListGroup.Item className="text-muted shadow-sm border-0 rounded-lg text-center">
                  Sem marcações registadas
                </ListGroup.Item>
              )}
            </ListGroup>
          )}
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Selecionar um anestesista</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingUsers ? (
            <Spinner animation="border" />
          ) : (
            <ListGroup>
              {users.map((user) => (
                <ListGroup.Item
                  key={user.id}
                  onClick={() => handleSelectUser(user)}
                  className={`selectable-item ${
                    selectedUser?.id === user.id ? "selected-item" : ""
                  }`}
                  style={{
                    marginBottom: "10px", // Espaço entre os itens
                    padding: "15px", // Espaçamento interno
                    cursor: "pointer", // Indica que o item é clicável
                    border: selectedUser?.id === user.id ? "2px solid #0d6efd" : "1px solid #ddd", // Destaque na borda do item selecionado
                    borderRadius: "5px", // Cantos arredondados
                    backgroundColor: selectedUser?.id === user.id ? "#e7f1ff" : "transparent", // Fundo azul claro para o item selecionado
                  }}
                >
                  {user.creator.username} - {user.creator.email}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmSelection}
            disabled={!selectedUser}
          >
            Confirmar Seleção
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SchedulePage;

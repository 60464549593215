import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Spinner, Alert, Collapse, Row, Col } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Ícones para seta
import path from "../config";

const ScheduleQuestionary = ({ scheduleId }) => {
  const [questionary, setQuestionary] = useState(null); // Estado para armazenar o questionário
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [error, setError] = useState(null); // Estado de erro
  const [isOpen, setIsOpen] = useState(false);


  // Função para buscar o questionário por ID
  const fetchQuestionary = async () => {
    try {
      const response = await axios.get(
        path + `questionary/GetScheduleQuestionaryByScheduleId/${scheduleId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      setQuestionary(response.data); // Atualiza o estado com os dados do questionário
    } catch (error) {

    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  useEffect(() => {
    fetchQuestionary(); // Busca os dados ao carregar o componente
  }, [scheduleId]);


  if (loading) return <Spinner animation="border" role="status" className="mt-5" />;
  if (error) return <Alert variant="danger">{error}</Alert>;
  if (!questionary) return <Card className="mb-2"><Card.Body>Questionário não encontrado.</Card.Body></Card>;

  return (
    <div className="container" style={{ marginTop: "30px" }}>
      <h2 className="mb-4">
        Detalhes Questionário
      </h2>
      <Card className="mt-4">
        <Card.Body>
          <Row>
            {/* Conteúdo Visível por Padrão */}
            <Col md={6}>
              <div className="mb-3">
                <strong>Histórico Médico:</strong>
                <p className="text-muted">{questionary.medicalHistory || "Não especificado"}</p>
              </div>
              <div className="mb-3">
                <strong>Espécie:</strong>
                <p className="text-muted">{questionary.species || "Não especificada"}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <strong>Raça:</strong>
                <p className="text-muted">{questionary.breed || "Não especificada"}</p>
              </div>
              <div className="mb-3">
                <strong>Idade:</strong>
                <p className="text-muted">{questionary.age || "Não especificada"}</p>
              </div>
            </Col>
          </Row>

          {/* Conteúdo Colapsável */}
          <Collapse in={isOpen}>
            <div>
              <Row className="mt-4">
                <Col md={6}>
                  <div className="mb-3">
                    <strong>Peso:</strong>
                    <p className="text-muted">{questionary.weight || "Não especificado"}</p>
                  </div>
                  <div className="mb-3">
                    <strong>Exame Físico:</strong>
                    <p className="text-muted">{questionary.physicalExam || "Não especificado"}</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <strong>Exames Adicionais:</strong>
                    <p className="text-muted">{questionary.additionalExams || "Não especificados"}</p>
                  </div>
                  <div className="mb-3">
                    <strong>Procedimento Planejado:</strong>
                    <p className="text-muted">{questionary.plannedProcedure || "Não especificado"}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <strong>Medicação Crónica:</strong>
                  <p className="text-muted">{questionary.chronicMedication || "Não especificada"}</p>
                </Col>
                <Col md={6}>
                  <strong>Temperamento:</strong>
                  <p className="text-muted">{questionary.temperament || "Não especificado"}</p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={6}>
                  <strong>Comentários:</strong>
                  <p className="text-muted">{questionary.comments || "Sem comentários adicionais."}</p>
                </Col>
                <Col md={3}>
                  <strong>Criado por:</strong>
                  <p className="text-muted">{questionary.creator?.username || "Desconhecido"}</p>
                </Col>
                <Col md={3}>
                  <strong>Data de Criação:</strong>
                  <p className="text-muted">{new Date(questionary.createdAt).toLocaleString()}</p>
                </Col>
              </Row>
            </div>
          </Collapse>
                  {/* Ícone de Expandir/Colapsar */}
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            color: "#12747D", 
            fontSize: "12px"
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <>
              <FaChevronUp /> Menos
            </>
          ) : (
            <>
              <FaChevronDown /> Mais
            </>
          )}
        </div>
        </Card.Body>

      </Card>

    </div>
  );
};

export default ScheduleQuestionary;

// src/components/Home.js
import React, { useEffect, useState } from "react";
import { Container, Button, Modal, Card, Spinner,Form  } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import FullCalendar from "@fullcalendar/react"; // FullCalendar React component
import timeGridPlugin from "@fullcalendar/timegrid"; // Plugin for time grid view
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt"; // Portuguese locale
import { format } from "date-fns"; // Import the date formatting function
import path from "../config";

function Home() {
  // State to store user's availability and modal inputs
  const [availabilities, setAvailabilities] = useState([]);
  const [selectedRange, setSelectedRange] = useState(null); // For storing selected date range
  const [error, setError] = useState("");
  const [currentEvent, setCurrentEvent] = useState(null); // To store the current event being edited
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreating, setisCreating] = useState(false);
  const [anesthetists, setAnesthetists] = useState([]); // Lista de anestesistas
  const [selectedAnesthetist, setSelectedAnesthetist] = useState("Todos"); // Anestesista selecionado

  // Mapa para armazenar cores dos anestesistas
  const [colorMap, setColorMap] = useState({});

  // Gera uma cor aleatória no formato hexadecimal
  const generateRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  // New state variables to store the current visible date range
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");

  // Função para determinar a visualização com base no tamanho da tela
  const updateViewBasedOnScreenSize = () => {
    console.log(window.innerWidth);
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      // Se a largura da tela for menor ou igual a 768px (md ou menor)
      return "timeGridDay"; // Exibe apenas o dia
    } else {
      return "timeGridWeek"; // Exibe a semana
    }
  };

  const updateButtonBasedOnScreenSize = () => {
    console.log(window.innerWidth);
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      // Se a largura da tela for menor ou igual a 768px (md ou menor)
      return ""; // Exibe apenas o dia
    } else {
      return "timeGridWeek,timeGridDay"; // Exibe a semana
    }
  };

  const fetchAnesthetists = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.get(`${path}user/getAnesthetist`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAnesthetists([{ id: "Todos", username: "Todos" }, ...response.data]);
    } catch (error) {
      console.error("Erro ao buscar anestesistas:", error);
    }
  };

  const fetchAvailability = async (startDate, endDate, anesthetistId = "Todos") => {
    try {
      const token = localStorage.getItem("userToken");

      const response = await axios.get(
        `${path}client/Availability?startDate=${startDate}&endDate=${endDate}&anesthetistId=${anesthetistId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const updatedColorMap = { ...colorMap }; // Cópia do mapa de cores existente

      // Map availability data to FullCalendar events format
      const events = response.data.map((availability) => {
        const anesthetist = availability.creator?.username || "Anônimo";

        // Se o anestesista não tiver uma cor atribuída, gera uma nova
        if (!updatedColorMap[anesthetist]) {
          updatedColorMap[anesthetist] = generateRandomColor();
        }

        return {
          id: availability.id,
          title: `Disponibilidade do: - ${anesthetist}`,
          start: availability.startDate,
          end: availability.endDate,
          allDay: false,
          backgroundColor: updatedColorMap[anesthetist],
          borderColor: updatedColorMap[anesthetist],
        };
      });

      setColorMap(updatedColorMap); // Atualiza o mapa de cores no estado
      setAvailabilities(events);
      setError("");
    } catch (error) {
      setError(
        "Falha ao buscar disponibilidades. Verifique os dados e tente novamente."
      );
      console.error(error);
    }
  };


  // Fetch availability when the date range changes
  const handleDatesSet = (dateInfo) => {
    const startDate = format(new Date(dateInfo.startStr), "yyyy-MM-dd"); // Format start date as YYYY-MM-DD
    const endDate = format(new Date(dateInfo.endStr), "yyyy-MM-dd"); // Format end date as YYYY-MM-DD

    // Update the current visible date range
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
    // Fetch availability for the visible range
    fetchAvailability(startDate, endDate, selectedAnesthetist);
  };

  const handleAnesthetistChange = (event) => {
    const selectedId = event.target.value;
    setSelectedAnesthetist(selectedId);
    fetchAvailability(currentStartDate, currentEndDate, selectedId);
  };

  useEffect(() => {
    fetchAnesthetists();
  }, []);

  // Handle scheduling
  const handleSchedule = async () => {
    setisCreating(true);
    try {
      const token = localStorage.getItem("userToken"); // Assuming the JWT is stored here

      await axios.post(
        path + "client/mySchedule",
        {
          startDate: selectedRange.startStr, // Date in YYYY-MM-DD format
          endDate: selectedRange.endStr,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token for authentication
            "Content-Type": "application/json",
          },
        }
      );

      // Refresh availability after saving
      await fetchAvailability(currentStartDate, currentEndDate);
      setisCreating(false);
      setSelectedRange(null); // Clear selected range
      setError(""); // Clear any previous errors
    } catch (error) {
      setisCreating(false);
      setError("Failed to schedule availability. Please try again.");
    }
  };

  // Handle scheduling Delete
  const handleScheduleDelete = async () => {
    setIsDeleting(true);
    try {
      const token = localStorage.getItem("userToken"); // Assuming the JWT is stored here

      await axios.delete(path + `client/mySchedule/${currentEvent.id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token for authentication
          "Content-Type": "application/json",
        },
      });

      // Refresh availability after saving
      await fetchAvailability(currentStartDate, currentEndDate);
      setIsDeleting(false);
      setCurrentEvent(null);
      setError(""); // Clear any previous errors
    } catch (error) {
      setIsDeleting(false);
      setError("Cannot delete the selected event.");
    }
  };

  const handleEventClick = (eventClickInfo) => {
    const { event } = eventClickInfo;
    const { start, end } = event; // Get event details

    const eventStart = new Date(start);
    const eventEnd = new Date(end);
    const currentDate = new Date();

    // Do nothing if the event is in the past
    if (eventEnd < currentDate) {
      return;
    }

    // Do nothing if the event is currently happening
    if (eventStart <= currentDate && eventEnd >= currentDate) {
      return;
    }

    // Set the selected range and open the modal for deletion
    setSelectedRange({ start, end });
    setCurrentEvent(event); // Set the current event for modification
  };

  return (
    <>
      {/* Fetch My Availability */}
      <Container>
        <h2 className="text-center mb-4 fw-bold textVet">
          Disponibilidade dos Anestesistas
        </h2>
        {/* Display Calendar */}
        {error && (
          <Card className="card-spacing">
            <Card.Body>
              <p className="text-danger">{error}</p>
            </Card.Body>
          </Card>
        )}
        <Form.Group controlId="anesthetistSelect" className="mb-3">
          <Form.Label>Selecionar Anestesista</Form.Label>
          <Form.Select
            value={selectedAnesthetist}
            onChange={handleAnesthetistChange}
          >
            {anesthetists.map((anesthetist) => (
              <option key={anesthetist.id} value={anesthetist.id}>
                {anesthetist.username}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Card>
          <Card.Body>
            <FullCalendar
              plugins={[interactionPlugin, timeGridPlugin]} // Include both plugins
              initialView={updateViewBasedOnScreenSize()} // Set initial view to week view with time slots
              events={availabilities} // Pass the availability events to FullCalendar
              locale={ptLocale}
              height="auto"
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: updateButtonBasedOnScreenSize(),
              }}
              selectable={true} // Enable selection
              allDaySlot={false} // Hide "all-day" slot
              slotMinTime={"06:00:00"} // Set minimum time to 6 AM
              slotMaxTime={"19:00:00"} // Set maximum time to 6 PM
              nowIndicator={true} // Optional: highlight the current time
              datesSet={handleDatesSet} // Fetch availability when the view changes
              eventClick={handleEventClick} // Handle event click
            />
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Home;
